<template>
  <div id="demo-basic-card" class="access-granted">
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <div class="vx-card mb-base">
          <div class="vx-card__header">
            <div class="vx-card__title" v-if="hasPermission == true">
              <h4 class="">Access granted</h4>
              <p>Access granted for {{ childName }}.Please click <a href="#" @click.prevent="$router.push({ name: 'front-login' });">here</a> to login</p>
              <!---->
            </div><!---->
            <div class="vx-card__title" v-if="hasPermission == false">
              <h4 class="">Access denied</h4>
              <p>You don't have permission to access this link.Please click <a href="#" @click.prevent="$router.push({ name: 'front-login' });">here</a> to login</p>
              <!---->
            </div><!---->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {

  data() {
    return {
      childName: 'child',
      hasPermission:null,
      childId:this.$route.params.childId,
      parentId:this.$route.params.parentId,
    };
  },
  methods: {
    ...mapActions("parent", [
      "accessGuardianToChild",
      "checkGuardianChild",
    ]),

    giveAccessGuardianToChild(){

      let data = {
        childId:this.childId,
        parentId:this.parentId
      }
      this.accessGuardianToChild(data)
        .then((res)   => {
          if  (res.data.data) {
            this.childName = res.data.data.fullName;
          }
          this.$vs.notify({
            title: 'Success',
            text: "Access Invitation sent Successfully",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
          this.popupActive = false;

          this.hasPermission = true;


        })
        .catch(err => { console.error(err) })
    }
  },

  async created() {
    let data = {
      childId:this.childId,
      parentId:this.parentId
    }

    await this.giveAccessGuardianToChild();
  },
  computed: {},
  components: {}

}
</script>
